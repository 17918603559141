import classnames from 'classnames';
import { TFunction, useTranslation } from 'next-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import ListLoadingSpinner from '@cineamo/legacy-frontend-lib/src/components/ui/animation/ListLoadingSpinner';
import Badge, { BadgeStyle } from '@cineamo/legacy-frontend-lib/src/components/ui/badge/Badge';
import { ActionIcon } from '@cineamo/legacy-frontend-lib/src/components/ui/buttons/ActionIcon';
import LinkWithStyle from '@cineamo/legacy-frontend-lib/src/components/ui/link/LinkWithStyle';
import Switch from '@cineamo/legacy-frontend-lib/src/components/ui/switch/Switch';

import { MessageDto } from '@cineamo/legacy-frontend-lib/src/models/message/MessageDto.types';
import { UserMeDto } from '@cineamo/legacy-frontend-lib/src/models/user/UserMeDto.types';

import { getMessages } from '@cineamo/legacy-frontend-lib/src/api/message/message-api';

import { apiResponseHandler } from '@cineamo/legacy-frontend-lib/src/helper/api-response-helper/ApiResponseHelper';

import useClickOutside from '@cineamo/legacy-frontend-lib/src/hooks/useClickOutside';

import { useNotificationsStore } from '@cineamo/legacy-frontend-lib/src/store/notifications/notificationsStore';

import { IconClose16 } from '@cineamo/legacy-frontend-lib/src/icons/menu/ic-close';

import NavNotificationsListItem from './NavNotificationsListItem';

export type NotificationsInDropDownProps = {
    setShowNotifications: Dispatch<SetStateAction<boolean>>;
    showNotifications: boolean;
    notificationsDropDownRef?;
    onBackClick?: () => void;
    user: UserMeDto;
    showAllButtonHref?: string;
    t?: TFunction;
};

export default function NotificationsInDropDown(props: NotificationsInDropDownProps): JSX.Element {
    const t = props.t || useTranslation('common').t;

    const { showNotifications, setShowNotifications, notificationsDropDownRef, onBackClick, user } = props;

    const {
        notifications: allMessages,
        unreadNotifications: unreadMessages,
        unreadNotificationsCount,
        setNotifications,
        setUnreadNotifications,
        setUnreadNotificationsCount
    } = useNotificationsStore();

    const [unreadOnlyIsOn, setUnreadOnlyIsOn] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);

    useEffect(() => {
        return;
    }, [allMessages, unreadMessages, showNotifications]);

    function handleSetUnreadNotifications(messageListResponse: MessageDto[]) {
        setUnreadNotifications(messageListResponse);
        setUnreadNotificationsCount(messageListResponse.length);
    }

    const { ref: dropDownRef } = useClickOutside(handleClickOutside);

    const { isFetching: isFetchingMessageResponse } = useQuery(
        ['messages', unreadOnlyIsOn, user],
        async () => {
            const res = await getMessages({
                userId: props.user.id,
                isRead: unreadOnlyIsOn ? false : null,
                sort: 'createdDatetime',
                order: 'desc'
            });
            apiResponseHandler(res, (successResponse) => {
                const messageListResponse = successResponse?._embedded?.messages || [];
                unreadOnlyIsOn
                    ? handleSetUnreadNotifications(messageListResponse)
                    : setNotifications(messageListResponse);
                return messageListResponse;
            });
            return res;
        },
        {
            //     initialData: { messages: [], _embedded: { messages: allMessages } }
            staleTime: 5000
        }
    );

    const messageListResponse: MessageDto[] = (unreadOnlyIsOn ? unreadMessages : allMessages) || [];

    function handleClickOutside() {
        setShowNotifications(false);
        props.setShowNotifications && props.setShowNotifications(false);
    }

    return (
        <div
            className={classnames(
                'z-[150] fixed md:absolute whitespace-nowrap flex flex-col rounded-4 bg-white',
                'left-0 top-0 md:left-auto md:right-0 md:top-full md:bottom-auto',
                'min-w-72 w-[98dvw] md:w-400 lg:w-500',
                'h-[90dvh] md:h-auto',
                'ml-4 my-8',
                'text-black text-body-14 overflow-hidden transform transition-all duration-300',
                {
                    'max-h-screen': showNotifications,
                    'max-h-0': !showNotifications
                }
            )}
            ref={dropDownRef}>
            <div
                className="border-violetGrey border-b border-opacity-50 px-16 py-16 space-y-12"
                ref={notificationsDropDownRef}>
                <div className="text-body-bold-14 flex flex-row items-center space-x-8">
                    <ActionIcon
                        className="w-16 h-16"
                        // TODO: Handle back click for Navigation.tsx icon={<IconLeftArrowBlk className="w-16 h-16"/>}
                        icon={<IconClose16 />}
                        action={() => {
                            onBackClick ? onBackClick() : setShowNotifications(false);
                        }}
                    />
                    <span>{t('common:notifications.title')}</span>
                    {unreadNotificationsCount > 0 && (
                        <Badge
                            name={unreadNotificationsCount.toString()}
                            badgeStyle={BadgeStyle.large}
                            bgColor="bg-cyan-middle bg-opacity-100"
                            textColor="text-darkBlue"
                        />
                    )}
                </div>
                <div className="flex flex-row items-center space-x-8">
                    <span className="text-body-14">{t('notifications.show-unread')}</span>
                    <Switch
                        key="show_only_unread"
                        onText={t('on')}
                        offText={t('off')}
                        onChange={(state) => setUnreadOnlyIsOn(state)}
                    />
                </div>
            </div>
            <div className="h-0 md:min-h-[400px] flex-auto overflow-y-auto">
                {isFetchingMessageResponse && <ListLoadingSpinner dotClass="bg-darkBlue" />}
                {!isFetchingMessageResponse &&
                    (messageListResponse?.length > 0 ? (
                        messageListResponse.slice(0, 6).map((message, index) => (
                            <div
                                key={index}
                                className={classnames(
                                    'border-violetGrey border-b border-opacity-50',
                                    unreadOnlyIsOn && 'last:border-transparent'
                                )}>
                                <NavNotificationsListItem
                                    notification={message}
                                    onReadClick={(notification) => setSelectedMessage(notification)}
                                    selectedNotification={selectedMessage}
                                    t={t}
                                />
                            </div>
                        ))
                    ) : (
                        <div>
                            <div className="p-24 text-opacity-50">
                                {unreadOnlyIsOn ? t('notifications.no-unread') : t('notifications.empty-inbox')}
                            </div>
                        </div>
                    ))}
            </div>
            <LinkWithStyle
                href={props.showAllButtonHref || '/notifications'}
                onClick={() => {
                    setShowNotifications(false);
                }}>
                <span className="w-full py-8 text-center text-overline-16 text-purple clickable-element">
                    {t('notifications.show-all')}
                </span>
            </LinkWithStyle>
        </div>
    );
}
